import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import WebhookLogs from "components/webhooks/logs";

export const WebhookLogsDrawer = () => {
  const { t } = useTranslation();
  const { webhookId } = useParams();

  const title = t("webhook_drawer:tab_keys:logs");

  return (
    <ChannexDrawerRoutable title={title} dataCy="webhook_drawer">
      <WebhookLogs webhookId={webhookId} />
    </ChannexDrawerRoutable>
  );
};
