import { validationMessages } from "config/constants/errors";

import { yup } from "utils/validation/yup";

export const validationSchema = yup.object().shape({
  propertyId: yup.string().required(validationMessages.required()),
  otaReservationCode: yup.string().required(validationMessages.required()),
  otaName: yup.string().required(validationMessages.required()),
  bookingDateRange: yup.array().required(validationMessages.required)
    .test("isFilled", validationMessages.required(), (value) => {
      return value.every(Boolean);
    }),
});
