import { validationMessages } from "config/constants/errors";

import { yup } from "utils/validation/yup";

import { validationSchema as roomValidationSchema } from "./_common/rooms/validation_schema";

export const validationSchema = yup.object().shape({
  propertyId: yup.string().required(validationMessages.required()),
  otaReservationCode: yup.string().required(validationMessages.required()),
  otaName: yup.string().required(validationMessages.required()),
  currency: yup.string().required(validationMessages.required()),
  bookingDateRange: yup.array().required(validationMessages.required)
    .test("isFilled", validationMessages.required(), (value) => {
      return value.every(Boolean);
    }),
  customer: yup.object().shape({
    surname: yup.string().required(validationMessages.required()),
  }),
  otaCommission: yup.string()
    .validNumber(validationMessages.number())
    .nullable(),
  rooms: yup.array()
    .of(roomValidationSchema),
});
