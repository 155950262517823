import React from "react";
import { Outlet } from "react-router-dom";

import * as Page from "components/page";

import WebhooksCRUD from "./components/webhooks_crud";

export default function WebhooksPage() {
  return (
    <Page.Main>
      <WebhooksCRUD />
      <Outlet />
    </Page.Main>
  );
}
