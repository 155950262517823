import React from "react";
import { Flex } from "antd";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";

export const ScrollBox = ({
  header,
  sidebar,
  sidebarWidth = 250,
  content,
  footer,
}) => {
  return (
    <BodyContainer>
      {header && header}
      <Flex style={{ overflowY: "auto", height: "100%" }} align="flex-start">
        {sidebar && (
          <div style={{ maxWidth: sidebarWidth, padding: "16px 16px" }}>
            {sidebar}
          </div>
        )}
        <ScrollableContainer leftBorder={!!sidebar} padding="16px 16px">
          {content}
        </ScrollableContainer>
      </Flex>
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </BodyContainer>
  );
};
