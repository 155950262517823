import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import FormWrapper from "components/webhooks/form_wrapper";

export const WebhookFormDrawer = () => {
  const { t } = useTranslation();
  const { webhookId } = useParams();

  const title = t(webhookId ? "webhook_drawer:edit_header" : "webhook_drawer:create_header");

  return (
    <ChannexDrawerRoutable title={title} dataCy="webhook_drawer">
      {({ handleCloseFromContent }) => (
        <FormWrapper entityId={webhookId} onClose={handleCloseFromContent} />
      )}
    </ChannexDrawerRoutable>
  );
};
