import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import classifyApiErrors from "utils/classify_api_errors";
import { flattenObject } from "utils/flatten_object";
import { logDeprecated } from "utils/log_deprecated";
import { nullifyEmptyValues } from "utils/nullify_empty_values";
import channexApiErrorParser from "utils/parse_api_errors";

/**
 * Custom form hook that extends react-hook-form with additional functionality for API error handling,
 * value transformation, and advanced form state management.
 *
 * @param {Object} options - Configuration options for the form
 * @param {Object} [options.validationSchema] - Yup validation schema for form validation
 * @param {Object} [options.defaultValue] - @deprecated Initial values for form fields. Use initialValue instead
 * @param {Object} [options.initialValue] - Initial values for form fields
 * @param {string[]} [options.fieldNames=[]] - List of field names to track for API errors
 * @param {Function} [options.apiErrorParser] - Custom function to parse API errors. If not provided, uses default channexApiErrorParser
 * @param {Function} [options.submitHandler=()=>{}] - @deprecated Function to handle form submission
 * @param {Function} [options.onSubmit=()=>{}] - Function to handle form submission
 * @param {boolean} [options.resetAfterSubmit=false] - Whether to reset form after successful submission
 * @param {boolean|string[]} [options.nullifyEmptyFields=false] - Convert empty string values to null. If array provided, only specified fields will be nullified
 * @param {boolean} [options.ensureFieldsPresent=false] - Ensure all fieldNames have at least null value when submitting
 * @param {Object} [options.errors] - External errors object to be integrated with form errors
 * @param {boolean} [options.shouldUnregister=false] - Whether to unregister fields when they are removed
 * @param {Function} [options.toForm=(v)=>v] - Transform values before they are set in the form
 * @param {Function} [options.fromForm=(v)=>v] - Transform values before they are submitted
 *
 * @returns {Object} Form control object
 * @returns {Function} returns.handleSubmit - Submit handler that processes the form
 * @returns {Function} returns.setValue - Function to set value of a form field
 * @returns {Object} returns.errors - Current form errors
 * @returns {Object} returns.dirtyFields - Object containing fields that have been modified
 * @returns {boolean} returns.isValid - Whether the form is currently valid
 * @returns {Function} returns.clearErrors - Function to clear all form errors
 * @returns {Function} returns.setError - Function to set an error for a specific field
 * @returns {Object} returns.control - Form control object for controlled components
 * @returns {Function} returns.resetField - Function to reset a specific field to its default value
 * @returns {Function} returns.watch - Function to subscribe to form field changes
 * @returns {Function} returns.reset - Function to reset the entire form
 * @returns {Function} returns.trigger - Function to trigger form validation
 * @returns {Object} returns.formState - Complete form state object
 *
 * @example
 * const {
 *   handleSubmit,
 *   errors,
 *   control
 * } = useAppForm({
 *   validationSchema: yup.object().shape({
 *     email: yup.string().email().required(),
 *     password: yup.string().min(8).required()
 *   }),
 *   initialValue: {
 *     email: '',
 *     password: ''
 *   },
 *   onSubmit: async (values) => {
 *     await api.login(values);
 *   }
 * });
 */
export const useAppForm = (options) => {
  const {
    validationSchema,
    defaultValue, // @deprecated
    initialValue,
    fieldNames = [],
    apiErrorParser,
    submitHandler, // @deprecated
    onSubmit = () => {},
    resetAfterSubmit = false,
    nullifyEmptyFields = false,
    ensureFieldsPresent = false,
    errors,
    shouldUnregister = false,
    mode,
    reValidateMode,
    toForm = (v) => v,
    fromForm = (v) => v,
  } = options;

  logDeprecated(defaultValue, "defaultValue is deprecated. Please use initialValue instead.");

  const {
    handleSubmit: originalHandleSubmit,
    formState,
    clearErrors,
    setError,
    control,
    resetField,
    setValue,
    watch,
    reset,
    trigger,
    ...rest
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: toForm(defaultValue || initialValue),
    errors,
    shouldUnregister,
    mode,
    reValidateMode,
  });

  const autoFieldNames = Object.keys(flattenObject({ obj: rest.getValues() }));

  const handleSubmit = () => {
    clearErrors();

    originalHandleSubmit(async (values) => {
      try {
        let normalizedValues = values;

        if (nullifyEmptyFields) {
          normalizedValues = nullifyEmptyValues(values, nullifyEmptyFields);
        }

        if (ensureFieldsPresent) {
          if (fieldNames.length === 0) {
            throw new Error("fieldNames must be specified when ensureFieldsPresent is true");
          }

          fieldNames.forEach((fieldName) => {
            if (!_.get(normalizedValues, fieldName)) {
              _.set(normalizedValues, fieldName, null);
            }
          });
        }

        normalizedValues = fromForm(normalizedValues);

        logDeprecated(submitHandler, "submitHandler is deprecated. Please use onSubmit instead.");

        await (submitHandler || onSubmit)(normalizedValues);

        if (resetAfterSubmit) {
          reset();
        }
      } catch (error) {
        if (!error.isValidationError) {
          throw error;
        }

        let parsedErrors;

        if (apiErrorParser) {
          parsedErrors = apiErrorParser(error);
        } else {
          parsedErrors = channexApiErrorParser(error, { camelCaseFields: true, root: "booking" });
        }

        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, [...autoFieldNames, ...fieldNames]);

        if (Object.keys(globalErrors).length !== 0) {
          setError("root.global", {
            errors: globalErrors,
          });
        }

        Object.entries(formErrors).forEach(([fieldName, errorMessage]) => {
          setError(fieldName, {
            type: "api",
            message: errorMessage,
          });
        });
      }
    })();
  };

  return {
    handleSubmit,
    setValue,
    errors: formState.errors,
    dirtyFields: formState.dirtyFields,
    isValid: formState.isValid,
    clearErrors,
    setError,
    control,
    resetField,
    watch,
    reset,
    trigger,
    formState,
    ...rest,
  };
};
