import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Modal, Tag } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import styles from "./styles.module.css";
import generalStyles from "styles/general.module.css";

const { Webhooks } = store;
const DROPDOWN_TRIGGER = ["click"];
const confirm = Modal.confirm;

class WebhooksCRUD extends Component {
  state = {
    columnsToHide: 0,
  };

  tableRef = React.createRef();

  actions = () => {
    const { t, routes, navigate, property } = this.props;

    return (_text, record) => {
      const editLink = property
        ? pathBuilder(routes.userAppRoutes.properties.edit.editWebhook, {
          propertyId: property.id,
          webhookId: record.id,
        })
        : pathBuilder(routes.userAppRoutes.webhooks.edit, { webhookId: record.id });

      const logsLink = property
        ? pathBuilder(routes.userAppRoutes.properties.edit.logs, {
          propertyId: property.id,
          webhookId: record.id,
        })
        : pathBuilder(routes.userAppRoutes.webhooks.logs, { webhookId: record.id });

      const items = [
        !record.protected && {
          key: "webhooks_crud_actions_edit",
          onClick: () => navigate(editLink),
          label: (
            <div>
              {t("webhooks_crud:actions:edit")}
            </div>
          ),
        },
        {
          key: "webhooks_crud_actions_logs",
          onClick: () => navigate(logsLink),
          label: (
            <div>
              {t("general:action:logs")}
            </div>
          ),
        },
        !record.protected && {
          key: "webhooks_crud_actions_remove",
          onClick: () => this.onRemove(record), // eslint-disable-line react/no-this-in-sfc
          label: (
            <div>
              {t("webhooks_crud:actions:remove")}
            </div>
          ),
        },
      ].filter(Boolean);

      return (
        <Dropdown menu={{ items }} trigger={DROPDOWN_TRIGGER}>
          <a
            data-cy="crud_entry_actions_menu"
            className={generalStyles.actionsToggle}
            onClick={(event) => event.preventDefault()}
          >
            {t("general:actions")} <DownOutlined />
          </a>
        </Dropdown>
      );
    };
  };

  componentDidUpdate(prevProps) {
    const { activeProperty } = this.props;
    const isPropertyUnchanged = prevProps.activeProperty === activeProperty;

    if (isPropertyUnchanged) {
      return;
    }

    const { current } = this.tableRef;

    if (current) {
      current.resetTable();
    }
  }

  loadData = (query, pagination, order) => {
    const { activeProperty } = this.props;
    const filter = { callback_url: { has: query } };

    if (this.props.property) {
      filter.property_id = this.props.property.id;
    } else if (activeProperty) {
      filter.property_id = activeProperty;
    }

    return Webhooks.list(filter, pagination, order);
  };

  onRemove = (record) => {
    const { t } = this.props;

    confirm({
      title: t("webhooks_crud:remove_dialog:title"),
      content: t("webhooks_crud:remove_dialog:description"),
      onOk: () => {
        Webhooks.remove(record).then(() => {
          this.tableRef.current.reloadTable();
        });
      },
    });
  };

  columns = () => {
    const { t, property, propertyOptions } = this.props;
    const { columnsToHide } = this.state;

    let dataColumns = [
      {
        title: t("webhooks_crud:columns:event_mask"),
        dataIndex: "event_mask",
        key: "event_mask",
        className: styles.eventTypes,
        sorter: true,
        render: (value) => {
          if (value === "*") {
            return <Tag>All</Tag>;
          }

          let values;

          if (value.indexOf(",") !== -1) {
            values = value.split(",");
          } else {
            values = value.split(";");
          }

          if (values.length > 1) {
            return (
              <>
                <Tag key={values[0]}>{t(`webhooks_crud:events:${values[0]}`)}</Tag> + {values.length - 1}
              </>
            );
          }

          return values.map((el) => <Tag key={el}>{t(`webhooks_crud:events:${el}`)}</Tag>);
        },
      },
      {
        title: t("webhooks_crud:columns:callback_url"),
        dataIndex: "callback_url",
        key: "callback_url",
        className: styles.url,
        sorter: true,
      },
      {
        title: t("webhooks_crud:columns:property"),
        dataIndex: "property_id",
        key: "property_id",
        render: (value) => {
          return propertyOptions[value] ? propertyOptions[value].title : "N/A";
        },
      },
      {
        title: t("webhooks_crud:columns:is_active"),
        dataIndex: "is_active",
        key: "is_active",
        sorter: true,
        render: (value) => {
          return <Badge status={value === true ? "success" : "error"} />;
        },
      },
    ];

    if (property) {
      dataColumns = dataColumns.filter((column) => column.key !== "property_id");
    }

    const actionColumns = [
      {
        title: t("webhooks_crud:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions(),
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("webhooks_crud:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  render() {
    const { data, routes } = this.props;

    const createLink = this.props.property
      ? pathBuilder(routes.userAppRoutes.properties.edit.createWebhook, {
        propertyId: this.props.property.id,
      })
      : pathBuilder(routes.userAppRoutes.webhooks.create);

    return (
      <>
        <Media query="(max-width: 419px)" onChange={this.handleMediaChange(2)} />
        <Media
          query="(min-width: 420px) and (max-width: 679px)"
          onChange={this.handleMediaChange(2)}
        />
        <Media
          query="(min-width: 680px) and (max-width: 899px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media
          query="(min-width: 900px) and (max-width: 930px)"
          onChange={this.handleMediaChange(0)}
        />
        <Media query="(min-width: 931px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable
          data={data}
          componentRef={this.tableRef}
          emptyMessage={this.emptyMessage()}
          createLink={createLink}
          showCreateMessage={false}
          onTablePramsChange={this.handleTableQueryChange}
          columns={this.columns}
          loadData={this.loadData}
        />
      </>
    );
  }
}

const mapStateToProps = ({ webhooks, session, properties: { options } }) => {
  const { activeProperty } = session || {};
  const { entities } = webhooks || { entities: null };
  const propertyOptions = (options || []).reduce((acc, option) => {
    acc[option.id] = option;
    return acc;
  }, {});

  return {
    activeProperty,
    propertyOptions,
    data: entities ? Object.values(entities) : null,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(WebhooksCRUD)));
