import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ScrollBox } from "components/_v2/layout/scroll_box";
import { FormInput } from "components/forms/inputs/hook_form/_v2/input";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { GlobalErrors } from "components/forms/inputs/hook_form/global_errors";
import { Legend } from "components/forms/layout/legend";
import { StyledForm } from "components/forms/styled_form";

const FormFields = ({ fields }) => {
  const form = useFormContext();

  return fields.map((field, idx) => {
    if (field.type === "legend") {
      return <Legend key={idx}>{field.label}</Legend>;
    }

    if (field.render) {
      return (
        <React.Fragment key={idx}>
          {field.render({ form })}
        </React.Fragment>
      );
    }

    const FieldComponent = field.component || FormInput;
    const props = {
      label: field.label,
      name: field.name,
      placeholder: field.placeholder,
      disabled: field.disabled,
      ...(field.componentProps || {}),
    };

    if (field.component === FormSelect) {
      props.options = field.options;
      props.mode = field.mode;
      props.loading = field.loading;
    }

    if (field.onChange) {
      props.onFieldChange = field.onChange;
    }

    return (
      <FieldComponent
        key={idx}
        {...props}
      />
    );
  });
};

const BaseForm = ({ fields, defaultSubmit, isSubmitLoading }) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <GlobalErrors />
      <FormFields fields={fields} />
      {defaultSubmit && (
        <SubmitButton loading={isSubmitLoading}>
          {t("common:actions:save")}
        </SubmitButton>
      )}
    </StyledForm>
  );
};

export const FormSchemaRenderer = ({ schema }) => {
  const { t } = useTranslation();

  const {
    layout,
    fields,
    isSubmitLoading,
  } = schema;

  let formLayout = layout;

  if (typeof layout === "string") {
    formLayout = { type: layout };
  }

  if (formLayout?.type === "scrollBox") {
    return (
      <ScrollBox
        header={formLayout?.header}
        content={<BaseForm fields={fields} />}
        sidebar={formLayout?.sidebar}
        sidebarWidth={formLayout?.sidebarWidth}
        footer={
          formLayout?.footer || (
            <SubmitButton layout={false} loading={isSubmitLoading}>
              {t("common:actions:save")}
            </SubmitButton>
          )
        }
      />
    );
  }

  return (
    <BaseForm
      defaultSubmit
      fields={fields}
      isSubmitLoading={isSubmitLoading}
    />
  );
};
