import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required(errorMessages.required()),
  cancellationPolicyCategory: yup.string().required(errorMessages.required()),
  minLengthOfStay: yup.number(errorMessages.number()).typeError(errorMessages.number()).nullable(),
  maxLengthOfStay: yup.number(errorMessages.number()).typeError(errorMessages.number()).nullable(),
  minBookingLeadTimeHours: yup.number(errorMessages.number()).typeError(errorMessages.number()).nullable(),
  maxBookingLeadTimeHours: yup.number(errorMessages.number()).typeError(errorMessages.number()).nullable(),
  derivedRatePlanData: yup.object().default({}).shape({
    parentRatePlanId: yup
      .string()
      .trim()
      .test("required", errorMessages.required(), (value, ctx) => {
        const { mode } = ctx.from[1].value;

        if (mode === "manual") {
          return true;
        }

        return !!value;
      }),
    amount: yup
      .number()
      .positive(errorMessages.positive())
      .when("parentRatePlanId", {
        is: (fieldValue) => !!fieldValue,
        then: (schema) => schema
          .required(errorMessages.required())
          .typeError(errorMessages.number()),
      }),
    modifierType: yup
      .string()
      .when("parentRatePlanId", {
        is: (fieldValue) => !!fieldValue,
        then: (schema) => schema
          .required(errorMessages.required())
          .typeError(errorMessages.number()),
      }),
  }),
});
