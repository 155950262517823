import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import SubmitButton from "components/forms/buttons/submit_button";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { FormCheckbox } from "components/forms/inputs/hook_form/form_checkbox";
import FormInput from "components/forms/inputs/hook_form/form_input";
import { FormRangePicker } from "components/forms/inputs/hook_form/form_rangepicker";
import { StyledForm } from "components/forms/styled_form";
import { useAppForm } from "components/hook_form";

import { validationSchema } from "./validation_schema";

export const TaxForm = ({ initialValue, errors: parentErrors, onSubmit }) => {
  const { t } = useTranslation();

  const { control, errors, watch, setValue, handleSubmit } = useAppForm({
    defaultValue: initialValue,
    submitHandler: async (formValue) => {
      await onSubmit(formValue);
    },
    validationSchema,
    errors: parentErrors,
    fieldNames: ["name", "type", "amount", "percent", "chargeMode", "applicabilityRulePresent", "applicabilityRule.type", "applicabilityRule.minLos", "applicabilityRule.maxLos", "applicabilityRule.dateRange"],
    shouldUnregister: true,
  });

  const [type, isApplicabilityRulePresent, applicableRuleType] = watch(["type", "applicabilityRulePresent", "applicabilityRule.type"]);

  const buildChargeModeOptions = () => {
    if (type === "percentage_tax") {
      return [
        { value: "of_rent", label: t("listings:dicts:charge_mode:of_rent") },
        { value: "of_rent_and_fees", label: t("listings:dicts:charge_mode:of_rent_and_fees") },
      ];
    }

    if (type === "flat_tax") {
      return [
        { value: "per_stay", label: t("listings:dicts:charge_mode:per_stay") },
        { value: "per_guest", label: t("listings:dicts:charge_mode:per_guest") },
        { value: "per_night", label: t("listings:dicts:charge_mode:per_night") },
        { value: "per_guest_per_night", label: t("listings:dicts:charge_mode:per_guest_per_night") },
      ];
    }

    return [];
  };

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <FormInput
            name="name"
            label={t("common:terms:name")}
            placeholder={t("common:forms:fields:name:placeholder")}
            errors={errors?.name?.message}
            control={control}
          />

          <FormSelect
            name="type"
            label={t("common:terms:type")}
            placeholder={t("common:forms:fields:type:placeholders")}
            options={[
              { value: "flat_tax", label: t("listings:dicts:tax_type:flat_tax") },
              { value: "percentage_tax", label: t("listings:dicts:tax_type:percentage_tax") },
            ]}
            onFieldChange={() => {
              setValue("chargeMode", null);
            }}
            control={control}
          />

          <FormSelect
            name="chargeMode"
            label={t("listings:terms:charge_mode")}
            placeholder={t("listings:forms:fields:charge_mode:placeholder")}
            options={buildChargeModeOptions()}
            control={control}
          />

          {type === "flat_tax" && (
            <FormInput
              name="amount"
              label={t("common:terms:amount")}
              placeholder={t("common:forms:fields:amount:placeholder")}
              errors={errors?.amount?.message}
              control={control}
            />
          )}
          {type === "percentage_tax" && (
            <FormInput
              name="percent"
              label={t("common:terms:percent")}
              placeholder={t("common:forms:fields:percent:placeholder")}
              errors={errors?.percent?.message}
              control={control}
            />
          )}

          <FormCheckbox
            name="applicabilityRulePresent"
            label={t("listings:terms:applicability_rule")}
            errors={errors?.applicabilityRulePresent?.message}
            control={control}
          />

          {isApplicabilityRulePresent && (
            <>
              <FormSelect
                name="applicabilityRule.type"
                label={t("listings:terms:applicability_rule_type")}
                placeholder={t("listings:forms:fields:applicability_rule_type:placeholder")}
                options={[
                  { value: "length_of_stay_between", label: t("listings:dicts:applicability_rule_type:length_of_stay_between") },
                  { value: "booking_date_in", label: t("listings:dicts:applicability_rule_type:booking_date_in") },
                ]}
                control={control}
              />
              {applicableRuleType === "length_of_stay_between" && (
                <>
                  <FormInput
                    name="applicabilityRule.minLos"
                    label={t("listings:terms:min_los")}
                    placeholder={t("listings:forms:fields:min_los:placeholder")}
                    errors={errors?.applicabilityRule?.minLos?.message}
                    control={control}
                  />
                  <FormInput
                    name="applicabilityRule.maxLos"
                    label={t("listings:terms:max_los")}
                    placeholder={t("listings:forms:fields:max_los:placeholder")}
                    errors={errors?.applicabilityRule?.maxLos?.message}
                    control={control}
                  />
                </>
              )}
              {applicableRuleType === "booking_date_in" && (
                <FormRangePicker
                  style={{ width: "100%" }}
                  name="applicabilityRule.dateRange"
                  label={t("common:terms:date_range")}
                  errors={errors?.applicabilityRule?.dateRange?.message}
                  control={control}
                />
              )}
            </>
          )}
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton onClick={handleSubmit} layout={false}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
