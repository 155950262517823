import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { Badge } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";

import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";

const { Webhooks } = store;

class WebhooksLogs extends Component {
  state = {
    columnsToHide: 0,
  };

  tableRef = React.createRef();

  loadData = (_query, pagination, order) => {
    const { webhookId } = this.props;

    return Webhooks.logs(webhookId, pagination, order);
  };

  columns = () => {
    const { t, matches } = this.props;

    let dataColumns = [
      {
        title: t("webhook_drawer:logs:columns:inserted_at"),
        dataIndex: "inserted_at",
        key: "inserted_at",
        sorter: false,
        render: (value) => formatToLocal(value, t("formats:date_time_with_seconds")),
      },
      {
        title: t("webhook_drawer:logs:columns:event"),
        dataIndex: "event",
        key: "event",
        sorter: false,
      },
      {
        title: t("webhook_drawer:logs:columns:result"),
        dataIndex: "success",
        key: "success",
        sorter: false,
        render: (value) => {
          return <Badge status={value === true ? "success" : "error"} />;
        },
      },
      {
        title: "",
        key: "action",
        align: "right",
        render: (value) => {
          const currentPath = matches[matches.length - 1].pathname;
          const link = `${currentPath}/${value.id}`;

          return <Link to={link}>{t("webhook_drawer:logs:columns:view")}</Link>;
        },
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return dataColumns;
  };

  emptyMessage() {
    const { t } = this.props;
    return t("webhook_drawer:logs:empty_message");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  render() {
    const { data } = this.props;

    return (
      <>
        <CRUDTable
          data={data}
          componentRef={this.tableRef}
          emptyMessage={this.emptyMessage()}
          showCreateMessage={false}
          columns={this.columns}
          loadData={this.loadData}
          topBar={false}
        />

        <Outlet />
      </>
    );
  }
}

const mapStateToProps = ({ webhook_logs }) => {
  const { entities } = webhook_logs || { entities: null };

  return {
    data: entities ? Object.values(entities) : null,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(WebhooksLogs)));
