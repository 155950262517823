import React from "react";
import { Steps } from "antd";

import { useWizard } from "./context";

export const WizardSidebar = () => {
  const { steps, stepsErrors, seenSteps, currentStepIndex } = useWizard();

  const items = steps.map(({ title }, i) => {
    const step = {
      title,
      disabled: !seenSteps.includes(i),
    };

    if (seenSteps.includes(i)) {
      step.status = "finish";
    }

    if (stepsErrors.includes(i)) {
      step.status = "error";
    }

    if (i === currentStepIndex) {
      step.status = "process";
    }

    return step;
  });

  return (
    <Steps
      size="small"
      direction="vertical"
      current={currentStepIndex}
      items={items}
    />
  );
};
