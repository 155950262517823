import Flexbox from "components/flexbox";

import { MappingDirection } from "../../common/mapping_direction";
import { VirtualList } from "../../common/virtual_list";
import { RoomMappingRow } from "../_common/room_mapping_row";

import { RateMappingRow } from "./rate_mapping_row";
import { useMapping } from "./use_mapping";

export const RoomRatesPrimaryRatePerRoom = ({
  channelTitle,
  mappings,
  roomTypes,
  ratePlans,
  mappingOptions,
  mappingSettings,
  onChangeSettings,
  onChangeMapping,
}) => {
  const {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handlePrimaryRateChange,
    handleRateMappingChange,
    handleRateMappingDelete,
  } = useMapping({
    mappings,
    mappingOptions: mappingOptions || {}, // when channel is created mappings are rendered before mappingOptions are fetched
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  });

  return (
    <Flexbox flexDirection="column" fullHeight>
      <MappingDirection channelTitle={channelTitle} />
      <Flexbox>
        <VirtualList
          itemDataCy="mapping_row"
          items={mappingItems}
          renderItem={(mappingItem) => {
            if (mappingItem.type === "room") {
              return (
                <RoomMappingRow
                  mappingItem={mappingItem}
                  onChange={handleRoomMappingChange}
                  onDelete={handleRoomMappingDelete}
                />
              );
            }

            if (mappingItem.type === "rate") {
              return (
                <RateMappingRow
                  mappingItem={mappingItem}
                  onPrimaryRateChange={handlePrimaryRateChange}
                  onChange={handleRateMappingChange}
                  onDelete={handleRateMappingDelete}
                />
              );
            }

            throw new Error(`unsupported mapping item: ${mappingItem}`);
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

RoomRatesPrimaryRatePerRoom.MAPPING_TYPE = "ExternalRoomRate";
