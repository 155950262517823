import React, { memo } from "react";

import { MappingDeleteButton } from "components/mapping/mapping_row/mapping_delete_button";
import RoomTitle from "components/rooms/room_title";

import { MappingRow } from "../../../common/mapping_row";

import { MappingSelect } from "./mapping_select";

import styles from "../../room_rates/styles.module.css";

export const RoomMappingRow = memo(({
  mappingItem,
  onChange,
  onDelete,
}) => {
  const { externalRoom, mapping, roomTypes, isInvalid, isRemoved } = mappingItem;
  const roomTypeId = mapping?.roomTypeId;
  const isMapped = !!roomTypeId;

  // show delete mapping button when this is a normal mapping or when this is removed room with existing mapping
  // do not show delete mapping button when room displayed only as a parent for removed rate plan for such case
  // room will be marked as invalid and would contain removed rate plan
  const showDeleteButton = (isInvalid && isRemoved) || (!isInvalid && !isRemoved);

  return (
    <MappingRow
      isError={isInvalid}
      source={
        <RoomTitle room={externalRoom} />
      }
      target={(
        <div className={styles.column__withActions}>
          <div className={styles.columnContent}>
            <MappingSelect
              isInvalid={isInvalid}
              value={roomTypeId}
              options={roomTypes}
              onChange={(value) => {
                if (value === roomTypeId) {
                  return; // no change
                }

                const newMapping = {
                  roomTypeId: value,
                };
                onChange({ externalRoom, mapping: newMapping, prevMapping: mapping });
              }}
            />
          </div>
          <div className={styles.columnActions}>
            {showDeleteButton && <MappingDeleteButton visible={isMapped} onClick={() => onDelete({ externalRoom, mapping })} />}
          </div>
        </div>
      )}
    />
  );
});
