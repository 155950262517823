import React from "react";
import { Form } from "antd";
import classNames from "classnames";
import snakeCase from "lodash/snakeCase";

import getFieldLayout from "../../../utils/get_field_layout";

import styles from "./styles.module.css";

const FormItem = Form.Item;

export const AntdFormField = ({ children, ...props }) => {
  const {
    name,
    className,
    label,
    layout = true,
    grouped = false,
    centerLabel = false,
    view,
    error,
  } = props;

  const dataCy = name ? snakeCase(`field_${name.replace(".", "_")}`) : null;

  const itemClassName = classNames(className, {
    [styles.noLabel]: !label,
    [styles.centerLabel]: centerLabel,
  });

  return (
    <FormItem
      style={{
        alignContent: "center",
      }}
      className={itemClassName}
      {...getLayoutProps(layout, grouped, view)}
      {...getValidationProps(error)}
      label={label || <span />}
      data-cy={dataCy}
    >
      {children}
    </FormItem>
  );
};

export const getAntdFieldProps = (props) => {
  if (props.error || props.errors) {
    throw new Error("AntdFormField does not accept error or errors props. Errors are handled by react-hook-form.");
  }

  const className = classNames(props.fieldClassName, {
    [styles.withAddon]: !!props.addonAfter,
    [styles.groupedField]: props.grouped,
  });

  return {
    className,
    name: props.name,
    label: props.label,
    layout: props.layout,
    grouped: props.grouped,
    view: props.view,
  };
};

function getValidationProps(error) {
  if (!error) {
    return {};
  }

  return {
    validateStatus: "error",
    help: <span data-cy="input_error_message">{error.message}</span>,
  };
}

function getLayoutProps(layout, grouped, view) {
  if (!layout || grouped) {
    return {};
  }

  return getFieldLayout(view);
}
