import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { BodyContainer, FooterContainer, ScrollableContainer } from "components/_v2/channex_drawer";
import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import { FormInput } from "components/forms/inputs/hook_form/_v2/input";
import FormInputNumber from "components/forms/inputs/hook_form/form_input_number";
import { StyledForm } from "components/forms/styled_form";
import { useAppForm } from "components/hook_form";

import { hopperHomesErrorParser } from "../../../../api/error_parser/hopper_homes_error_parser";

import { validationSchema } from "./validation_schema";

export const DefaultPricingForm = ({ defaultValue, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const { handleSubmit, errors, watch, trigger, control, dirtyFields } = useAppForm({
    defaultValue,
    submitHandler: async (formValue) => {
      await onSubmit(formValue);
    },
    nullifyEmptyFields: true,
    validationSchema,
    apiErrorParser: (error) => hopperHomesErrorParser.parse(error.body.errors, t),
    fieldNames: ["nightlyPrice", "guestsIncludedRent", "additionalGuestFee", "dayOfWeekPrices.mon", "dayOfWeekPrices.tue", "dayOfWeekPrices.wed", "dayOfWeekPrices.thu", "dayOfWeekPrices.fri", "dayOfWeekPrices.sat", "dayOfWeekPrices.sun"],
  });

  const [nightlyPrice] = watch(["nightlyPrice"]);
  useEffect(() => {
    if (!dirtyFields.nightlyPrice) {
      return;
    }

    trigger("dayOfWeekPrices.mon");
    trigger("dayOfWeekPrices.tue");
    trigger("dayOfWeekPrices.wed");
    trigger("dayOfWeekPrices.thu");
    trigger("dayOfWeekPrices.fri");
    trigger("dayOfWeekPrices.sat");
    trigger("dayOfWeekPrices.sun");
  }, [dirtyFields.nightlyPrice, nightlyPrice, trigger]);

  return (
    <BodyContainer>
      <ScrollableContainer padding="0 16px">
        <StyledForm>
          <GlobalErrors hookForm errors={errors} />

          <FormInput
            name="nightlyPrice"
            label={t("listings:terms:nightly_price")}
            placeholder={t("listings:forms:fields:nightly_price:placeholder")}
            control={control}
          />

          <FormInputNumber
            name="guestsIncludedRent"
            label={t("listings:terms:guests_included_rent")}
            placeholder={t("listings:forms:fields:guests_included_rent:placeholder")}
            control={control}
          />

          <FormInput
            name="additionalGuestFee"
            label={t("listings:terms:additional_guest_fee")}
            placeholder={t("listings:forms:fields:additional_guest_fee:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.mon"
            label={t("listings:terms:day_of_week_prices_monday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_monday:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.tue"
            label={t("listings:terms:day_of_week_prices_tuesday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_tuesday:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.wed"
            label={t("listings:terms:day_of_week_prices_wednesday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_wednesday:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.thu"
            label={t("listings:terms:day_of_week_prices_thursday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_thursday:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.fri"
            label={t("listings:terms:day_of_week_prices_friday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_friday:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.sat"
            label={t("listings:terms:day_of_week_prices_saturday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_saturday:placeholder")}
            control={control}
          />

          <FormInput
            name="dayOfWeekPrices.sun"
            label={t("listings:terms:day_of_week_prices_sunday")}
            placeholder={t("listings:forms:fields:day_of_week_prices_sunday:placeholder")}
            control={control}
          />
        </StyledForm>
      </ScrollableContainer>
      <FooterContainer>
        <SubmitButton onClick={handleSubmit} loading={isLoading} layout={false}>
          {t("common:actions:save")}
        </SubmitButton>
      </FooterContainer>
    </BodyContainer>
  );
};
