import React from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";

import { AntdFormField } from "components/forms/inputs/hook_form/_v2/_base/antd_form_field";
import { FormInput } from "components/forms/inputs/hook_form/_v2/input";
import Legend from "components/forms/layout/legend/legend";

import styles from "./styles.module.css";

const GuestEmbeddedForm = ({ name, index, onRemove }) => {
  return (
    <AntdFormField
      label={`Guest ${index + 1}`}
      centerLabel
    >
      <Space.Compact style={{ width: "100%" }}>
        <FormInput
          name={`${name}.name`}
          placeholder="Name"
          grouped
        />
        <FormInput
          name={`${name}.surname`}
          placeholder="Surname"
          grouped
        />
        <Button className={styles.groupedAction} type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </Space.Compact>
    </AntdFormField>
  );
};

const buildNew = () => ({
  name: "",
  surname: "",
});

export const Guests = ({ name }) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({ name });

  const empty = fields.length === 0 && <Typography.Text type="secondary">{t("common:messages:no_items_added")}</Typography.Text> || null;

  return (
    <>
      <Legend empty={empty}>
        {t("common:headings:guests")}
        <Button
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={() => append(buildNew())}
        >
          {t("common:actions:add")}
        </Button>
      </Legend>

      {fields.map((field, index) => (
        <GuestEmbeddedForm
          key={field.id}
          index={index}
          name={`${name}.${index}`}
          onRemove={() => remove(index)}
        />
      ))}
    </>
  );
};
