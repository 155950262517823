import React from "react";
import { useSelector } from "react-redux";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { getActiveFeatureFlags } from "store/storage/selectors/session_selector";

import { features } from "config/feature_flags";

import AdminPage from "pages/admin_page";
import ApplicationAccessSubPage from "pages/admin_page/application_access_sub_page";
import BillingAccountsSubPage from "pages/admin_page/billing_accounts_sub_page/billing_accounts_sub_page";
import { ChannelAdaptersSubPage } from "pages/admin_page/channel_adapters_sub_page/channel_adapters_sub_page";
import PropertiesSubPage from "pages/admin_page/properties_sub_page/properties_sub_page";
import { UserRequestLimitsSubPage } from "pages/admin_page/user_request_limits_sub_page/user_request_limits_sub_page";
import UsersSubPage from "pages/admin_page/users_sub_page/users_sub_page";
import { WhiteLabelPartnersSubPage } from "pages/admin_page/white_label_partners_sub_page/white_label_partners_sub_page";
import { ApaleoAuthErrorPage } from "pages/applications_page/apaleo/auth/error";
import { ApaleoAuthSuccessPage } from "pages/applications_page/apaleo/auth/success";
import { ApaleoImportPage } from "pages/applications_page/apaleo/import/page";
import ApplicationSettings from "pages/applications_page/application_settings/application_settings";
import ApplicationsPage from "pages/applications_page/applications_page";
import BookingsPage from "pages/bookings_page/bookings_page";
import ChannelEventsPage from "pages/channel_events_page/channel_events_page";
import ChannelsPage from "pages/channels_page/channels_page";
import ChannexPCIPage from "pages/channex_pci_page/channex_pci_page";
import DashboardPage from "pages/dashboard_page/dashboard_page";
import GroupsPage from "pages/groups_page";
import InventoryPage from "pages/inventory_page/inventory_page";
import IssuesPage from "pages/issues_page/issues_page";
import Dialog from "pages/messages_page/components/dialog";
import MessagesPage from "pages/messages_page/messages_page";
import PropertiesPage from "pages/properties_page";
import QueryErrorPage from "pages/query_error_page";
import { ReportsPageLayout } from "pages/reports_page_layout";
import { ChannelsReport } from "pages/reports_page_layout/channels_report/channels_report";
import { ReviewsPage } from "pages/reviews_page";
import { PropertyScoreRoutableSection } from "pages/reviews_page/review_app/property_score_routable_section";
import { ReviewRoutableSection } from "pages/reviews_page/review_app/review_routable_section";
import RoomsPage from "pages/rooms_page/rooms_page";
import TasksPage from "pages/tasks_page/tasks_page";
import TriplaImportPage from "pages/tripla_import_page";
import UserProfilePage from "pages/user_profile_page";
import WebhooksPage from "pages/webhooks_page";

import AssignGroupFormDrawer from "drawers/assign_group_form";
import { AvailabilityRulesDrawer } from "drawers/availability_rules/drawer";
import BillingAccountDrawer from "drawers/billing_account_drawer";
import BookingViewLegacyDrawer from "drawers/booking_view_drawer/booking_view_drawer";
import ChannelEventsViewDrawerRoutable from "drawers/channel_events_view_drawer/channel_events_drawer";
import ChannelManagementDrawer from "drawers/channel_management_drawer";
import ChannelViewDrawer from "drawers/channel_view/channel_view_drawer";
import GroupManagementDrawer from "drawers/group_management_drawer";
import GroupUserAccessDrawer from "drawers/group_user_access/group_user_access_drawer";
import InventorySettingsDrawer from "drawers/inventory_settings_drawer";
import IssueDrawerRoutable from "drawers/issue_drawer_routable/issue_drawer_routable";
import LiveFeedEventDrawer from "drawers/live_feed_event_drawer";
import PropertyFormDrawer from "drawers/property_form_drawer";
import { PropertyManageExtrasDrawer } from "drawers/property_manage_extras_drawer/property_manage_extras_drawer";
import PropertyUserAccessDrawer from "drawers/property_user_access/property_user_access_drawer";
import RateFormDrawerRoutable from "drawers/rate_form_drawer_routable/rate_form_drawer_routable";
import RoomFormDrawer from "drawers/room_form_drawer_routable/room_form_drawer_routable";
import RoomViewDrawer from "drawers/room_view";
import { StateChangesDrawerRoutable } from "drawers/state_changes_drawer/routable";
import TaskViewDrawer from "drawers/task_view/task_view";
import UserInviteDrawer from "drawers/user_invite_drawer";
import { WebhookFormDrawer } from "drawers/webhook_form_drawer";
import WebhookLogDrawer from "drawers/webhook_log_drawer";
import { WebhookLogsDrawer } from "drawers/webhook_logs_drawer";

import { BookingManageDrawerRoutable } from "components/bookings/booking_manage_drawer_routable";

import SignedUserConfirmationRedirect from "redirects/signed_user_confirmation_redirect";
import TokenExchangeSuccessRedirect from "redirects/token_exchange_success_redirect";
import buildNamedRoutes from "routing/build_named_routes";
import UserAppRoutingContext from "routing/user_app_routing_context";

import EntryPoint from "./entry_point";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default function UserApp() {
  const activeFeatureFlags = useSelector(getActiveFeatureFlags);

  const USER_ROUTES = [
    {
      name: "dashboard",
      path: "/",
      element: <DashboardPage />,
      children: [
        {
          name: "bookingView",
          path: "/dashboard/bookings/:bookingId",
          element: <BookingViewLegacyDrawer />,
        }, {
          name: "channelEventView",
          path: "/dashboard/events/:actionId",
          element: <ChannelEventsViewDrawerRoutable />,
        }, {
          name: "issueView",
          path: "/dashboard/issues/:issueId",
          element: <IssueDrawerRoutable />,
        }, {
          name: "liveFeedEventView",
          path: "/dashboard/live_feed_events/:eventId",
          element: <LiveFeedEventDrawer />,
        },
      ],
    },
    {
      name: "inventory",
      path: "/inventory",
      element: <InventoryPage />,
      children: [{
        name: "settings",
        path: "/inventory/settings",
        element: <InventorySettingsDrawer />,
      }, {
        name: "availabilityRules",
        path: "/inventory/availability-rules",
        element: <AvailabilityRulesDrawer />,
      }, {
        name: "stateChangesDrawer",
        path: "/inventory/state-changes",
        element: <StateChangesDrawerRoutable />,
      }],
    },
    {
      name: "bookings",
      path: "/bookings",
      element: <BookingsPage />,
      children: [{
        name: "view",
        path: "/bookings/:bookingId",
        element: <BookingViewLegacyDrawer />,
      }, {
        name: "edit",
        path: "/bookings/:bookingId/edit",
        element: <BookingManageDrawerRoutable />,
      }, {
        name: "new",
        path: "/bookings/new",
        element: <BookingManageDrawerRoutable />,
      }],
    },
    {
      name: "issues",
      path: "/issues",
      element: <IssuesPage />,
      children: [{
        name: "view",
        path: "/issues/:issueId",
        element: <IssueDrawerRoutable />,
      }],
    },
    {
      name: "properties",
      path: "/properties",
      element: <PropertiesPage />,
      children: [
        {
          name: "create",
          path: "/properties/create",
          element: <PropertyFormDrawer />,
        }, {
          name: "manageExtras",
          path: "/properties/:propertyId/extras",
          element: <PropertyManageExtrasDrawer />,
        }, {
          name: "edit",
          path: "/properties/:propertyId/edit",
          element: <PropertyFormDrawer />,
          children: [
            {
              name: "userEdit",
              path: "/properties/:propertyId/edit/:userId/edit",
              element: <PropertyUserAccessDrawer />,
            }, {
              name: "userInvite",
              path: "/properties/:propertyId/edit/invite",
              element: <PropertyUserAccessDrawer />,
            }, {
              name: "createWebhook",
              path: "/properties/:propertyId/edit/webhooks/create",
              element: <WebhookFormDrawer />,
            }, {
              name: "editWebhook",
              path: "/properties/:propertyId/edit/webhooks/:webhookId/edit",
              element: <WebhookFormDrawer />,
            }, {
              name: "logs",
              path: "/properties/:propertyId/edit/webhooks/:webhookId/logs",
              element: <WebhookLogsDrawer />,
              children: [{
                name: "viewLog",
                path: "/properties/:propertyId/edit/webhooks/:webhookId/logs/:webhookLogId",
                element: <WebhookLogDrawer />,
              }],
            },
          ],
        }, {
          name: "editGroup",
          path: "/properties/group/:groupId/edit",
          element: <GroupManagementDrawer />,
          children: [
            {
              name: "userInvite",
              path: "/properties/group/:groupId/edit/invite",
              element: <GroupUserAccessDrawer />,
            }, {
              name: "userEdit",
              path: "/properties/group/:groupId/edit/:userId/edit",
              element: <GroupUserAccessDrawer />,
            },
          ],
        }, {
          name: "assignGroup",
          path: "/properties/:propertyId/assign_group",
          element: <AssignGroupFormDrawer />,
        },
      ],
    },
    {
      name: "groups",
      path: "/groups",
      element: <GroupsPage />,
      children: [
        {
          name: "create",
          path: "/groups/create",
          element: <GroupManagementDrawer />,
        }, {
          name: "edit",
          path: "/groups/:groupId/edit",
          element: <GroupManagementDrawer />,
          children: [
            {
              name: "userInvite",
              path: "/groups/:groupId/edit/invite",
              element: <GroupUserAccessDrawer />,
            }, {
              name: "userEdit",
              path: "/groups/:groupId/edit/:userId/edit",
              element: <GroupUserAccessDrawer />,
            },
          ],
        },
        {
          name: "editProperty",
          path: "/groups/property/:propertyId/edit",
          element: <PropertyFormDrawer />,
          children: [
            {
              name: "userEdit",
              path: "/groups/property/:propertyId/edit/:userId/edit",
              element: <PropertyUserAccessDrawer />,
            }, {
              name: "userInvite",
              path: "/groups/property/:propertyId/edit/invite",
              element: <PropertyUserAccessDrawer />,
            },
          ],
        }, {
          name: "assignGroup",
          path: "/groups/:propertyId/assign_group",
          element: <AssignGroupFormDrawer />,
        },
      ],
    },
    {
      name: "rooms",
      path: "/rooms",
      element: <RoomsPage />,
      children: [
        {
          name: "create",
          path: "/rooms/create",
          element: <RoomFormDrawer />,
        }, {
          name: "view",
          path: "/rooms/:roomId/view",
          element: <RoomViewDrawer />,
        }, {
          name: "edit",
          path: "/rooms/:roomId/edit/:section",
          element: <RoomFormDrawer />,
        }, {
          name: "createRate",
          path: "/rooms/:roomId/rates/create",
          element: <RateFormDrawerRoutable />,
        }, {
          name: "ratesEditAtRooms",
          path: "/rooms/rates/:rateId/edit",
          element: <RateFormDrawerRoutable />,
        },
      ],
    },
    {
      name: "channels",
      path: "/channels",
      element: <ChannelsPage />,
      children: [
        {
          name: "create",
          path: "/channels/create",
          element: <ChannelManagementDrawer />,
        }, {
          name: "view",
          path: "/channels/:channelId/view",
          element: <ChannelViewDrawer />,
        }, {
          name: "edit",
          path: "/channels/:channelId/edit",
          element: <ChannelManagementDrawer />,
        },
      ],
    }, {
      name: "channelEvents",
      path: "/channel_events/:channelId",
      element: <ChannelEventsPage />,
      children: [
        {
          name: "view",
          path: "/channel_events/:channelId/event/:actionId",
          element: <ChannelEventsViewDrawerRoutable />,
        }, {
          name: "viewError",
          path: "/channel_events/:channelId/issue/:issueId",
          element: <IssueDrawerRoutable />,
        },
      ],
    }, {
      name: "tasks",
      path: "/tasks/:propertyId",
      element: <TasksPage />,
      children: [
        {
          name: "view",
          path: "/tasks/:propertyId/:taskId",
          element: <TaskViewDrawer />,
        },
      ],
    }, {
      name: "confirmRegistration",
      path: "/confirm-registration",
      element: <SignedUserConfirmationRedirect />,
    }, {
      name: "userProfile",
      path: "/user_profile",
      element: <UserProfilePage />,
    }, {
      name: "queryErrorPage",
      path: "/query-error",
      element: <QueryErrorPage />,
    }, {
      name: "messages",
      path: "/messages",
      element: <MessagesPage />,
      children: [
        {
          name: "dialog",
          path: "/messages/:threadId",
          element: <Dialog />,
          children: [{
            name: "liveFeedEventView",
            path: "/messages/:threadId/live_feed_events/:eventId",
            element: <LiveFeedEventDrawer />,
          }],
        },
      ],
    }, {
      name: "pci",
      path: "/pci",
      element: <ChannexPCIPage />,
    }, {
      name: "applications",
      path: "/applications",
      element: <ApplicationsPage />,
      children: [
        {
          name: "settings",
          path: "/applications/:installationId",
          element: <ApplicationSettings />,
        },
      ],
    }, {
      name: "admin",
      path: "/admin",
      element: <AdminPage />,
      children: [
        {
          name: "users",
          path: "/admin/users",
          element: <UsersSubPage />,
          children: [
            {
              name: "billing_account_edit",
              path: "/admin/users/billing_account/:billingAccountId/edit",
              element: <BillingAccountDrawer />,
            }, {
              name: "invite",
              path: "/admin/users/invite",
              element: <UserInviteDrawer />,
            },
          ],
        }, {
          name: "properties",
          path: "/admin/properties",
          element: <PropertiesSubPage />,
          children: [
            {
              name: "billing_account_edit",
              path: "/admin/properties/billing_account/:billingAccountId/edit",
              element: <BillingAccountDrawer />,
            }, {
              name: "edit",
              path: "/admin/properties/:propertyId/edit",
              element: <PropertyFormDrawer />,
              children: [
                {
                  name: "userEdit",
                  path: "/admin/properties/:propertyId/edit/:userId/edit",
                  element: <PropertyUserAccessDrawer />,
                }, {
                  name: "userInvite",
                  path: "/admin/properties/:propertyId/edit/invite",
                  element: <PropertyUserAccessDrawer />,
                },
              ],
            },
          ],
        }, {
          name: "billing_accounts",
          path: "/admin/billing_accounts",
          element: <BillingAccountsSubPage />,
          children: [
            {
              name: "edit",
              path: "/admin/billing_accounts/:billingAccountId/edit",
              element: <BillingAccountDrawer />,
            },
          ],
        },
        {
          name: "channel_adapters",
          path: "/admin/channel_adapters",
          element: <ChannelAdaptersSubPage />,
        },
        {
          name: "application_access",
          path: "/admin/application_access",
          element: <ApplicationAccessSubPage />,
        }, {
          name: "white_label_partners",
          path: "/admin/white_label_partners",
          element: <WhiteLabelPartnersSubPage />,
        },
        {
          name: "userRequestLimits",
          path: "/admin/user_request_limits",
          element: <UserRequestLimitsSubPage />,
        },
      ],
    }, {
      name: "webhooks",
      path: "/webhooks",
      element: <WebhooksPage />,
      children: [
        {
          name: "create",
          path: "/webhooks/create",
          element: <WebhookFormDrawer />,
        }, {
          name: "edit",
          path: "/webhooks/:webhookId/edit",
          element: <WebhookFormDrawer />,
        }, {
          name: "logs",
          path: "/webhooks/:webhookId/logs",
          element: <WebhookLogsDrawer />,
          children: [
            {
              name: "viewLog",
              path: "/webhooks/:webhookId/logs/:webhookLogId",
              element: <WebhookLogDrawer />,
            },
          ],
        },
      ],
    },
    {
      name: "default",
      path: "/auth/exchange",
      element: <TokenExchangeSuccessRedirect />,
    },
    {
      name: "reports",
      path: "/reports",
      element: <ReportsPageLayout />,
      children: [{
        name: "channelsReport",
        path: "/reports/channels",
        element: <ChannelsReport />,
      }],
    },
  ];

  USER_ROUTES.push({
    name: "reviews",
    path: "/reviews",
    element: <ReviewsPage />,
    children: [
      {
        name: "propertyScore",
        path: "/reviews",
        element: <PropertyScoreRoutableSection />,
      }, {
        name: "review",
        path: "/reviews/:reviewId",
        element: <ReviewRoutableSection />,
      },
    ],
  });

  if (activeFeatureFlags[features.TRIPLA_IMPORT]) {
    USER_ROUTES.push({
      name: "triplaImport",
      path: "/tripla-import",
      element: <TriplaImportPage />,
    });
  }

  USER_ROUTES.push({
    name: "apaleoImport",
    path: "/apaleo-import",
    element: <ApaleoImportPage appName="app_apaleo_connect" />,
  }, {
    name: "apaleoImport",
    path: "/apaleo-reserva-import",
    element: <ApaleoImportPage appName="app_apaleo_connect_reserva" />,
  }, {
    name: "apaleoAuthSuccess",
    path: "/apaleo-connect",
    element: <ApaleoAuthSuccessPage path="/apaleo-import" />,
  }, {
    name: "apaleoAuthError",
    path: "/apaleo-connect-error",
    element: <ApaleoAuthErrorPage path="/apaleo-import" />,
  }, {
    name: "apaleoAuthSuccess",
    path: "/apaleo-reserva-connect",
    element: <ApaleoAuthSuccessPage path="/apaleo-reserva-import" />,
  }, {
    name: "apaleoAuthError",
    path: "/apaleo-reserva-connect-error",
    element: <ApaleoAuthErrorPage path="/apaleo-reserva-import" />,
  });

  const routes = [{
    element: <EntryPoint />,
    children: USER_ROUTES,
  }, {
    path: "*",
    element: <Navigate to="/" />,
  }];

  const routesTree = buildNamedRoutes(routes);

  const router = sentryCreateBrowserRouter(routes);

  return (
    <UserAppRoutingContext.Provider value={routesTree}>
      <RouterProvider router={router} />
    </UserAppRoutingContext.Provider>
  );
}
