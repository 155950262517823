import React from "react";
import { useFieldArray, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tag, Typography } from "antd";
import get from "lodash/get";

import Legend from "components/forms/layout/legend/legend";

import useDrawer from "hooks/use_drawer";

import { EditDrawer } from "./edit_drawer";

const ServiceItem = ({ service, errors, onRemove, onEdit }) => {
  const { t } = useTranslation();

  const isError = Object.keys(errors || {}).length !== 0;

  return (
    <Flex justify="space-between">
      <span>
        {isError && <Tag icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>}
        {service.name}
      </span>
      <span>
        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
        <Button type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </span>
    </Flex>
  );
};

const buildNew = () => ({});

export const Services = ({ name, currency, layout }) => {
  const { t } = useTranslation();

  const { errors: formErrors } = useFormState();
  const errors = formErrors[name];

  const { fields, append, remove, update } = useFieldArray({ name });
  const entries = useWatch({ name });
  const { isOpen, payload, open, close } = useDrawer();
  const formState = useFormState();
  const sectionError = get(formState.errors, name)?.message;

  const empty = fields.length === 0 && <Typography.Text type="secondary">{t("common:messages:no_items_added")}</Typography.Text> || null;

  return (
    <>
      <Legend marginTop={layout !== "embedded"} error={sectionError} empty={empty}>
        {t("common:headings:services")}
        <Button
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={() => open({ entry: buildNew() })}
        >
          {t("common:actions:add")}
        </Button>
      </Legend>

      {fields.map((field, index) => (
        entries?.[index] && (
          <ServiceItem
            key={field.id}
            service={entries[index]}
            errors={errors?.[index]}
            onRemove={() => remove(index)}
            onEdit={() => {
              open({
                entry: entries[index],
                index,
              });
            }}
          />
        )
      ))}

      <EditDrawer
        visible={isOpen}
        service={payload?.entry}
        currency={currency}
        errors={errors?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </>
  );
};
