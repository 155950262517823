import React from "react";
import classnames from "classnames";

import styles from "./styles.module.css";

function Legend({ children, error, empty, marginBottom = true, marginTop = true }) {
  const className = classnames(styles.legend, {
    [styles.woBottomMargin]: !marginBottom,
    [styles.woTopMargin]: !marginTop,
    [styles.withError]: !!error,
  });

  return (
    <>
      <legend className={className}>{children}</legend>
      {error && (
        <div className={styles.withError}>{error}</div>
      )}
      {!error && empty && (
        empty
      )}
    </>
  );
}

export default Legend;
export { Legend };
