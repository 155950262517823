import { useTranslation } from "react-i18next";

import channelCodes from "config/constants/channels/channel_codes";

import { HopperHomesMapping } from "components/channel_management/channels/hopper_homes/mapping";
import { Ctoutvert } from "components/channel_management/components/mapping/ctoutvert";
import FormMessage from "components/forms/message/form_message";
import Loading from "components/loading/loading";

import { RoomRates } from "./room_rates/room_rates";
import { RoomRatesPrimaryRatePerRoom } from "./room_rates/room_rates_primary_rate_per_room";
import AirBNBMapping from "./airbnb_mapping";
import MultiOccupancyMapping from "./multioccupancy_mapping";
import RatesSelectMapping from "./rates_select";
import TreeMapping from "./tree_mapping";

const CHANNELS_MAPPING_COMPONENTS = {
  Agoda: {
    MappingComponent: MultiOccupancyMapping,
  },
  AirBNB: {
    MappingComponent: AirBNBMapping,
  },
  BookingCom: {
    MappingComponent: MultiOccupancyMapping,
  },
  CTrip: {
    MappingComponent: MultiOccupancyMapping,
  },
  Expedia: {
    MappingComponent: MultiOccupancyMapping,
  },
  GoogleHotelARI: {
    MappingComponent: RatesSelectMapping,
  },
  HotelTonight: {
    MappingComponent: MultiOccupancyMapping,
  },
  VerticalBooking: {
    MappingComponent: TreeMapping,
  },
  Wubook: {
    MappingComponent: TreeMapping,
  },
  OpenChannel: {
    MappingComponent: MultiOccupancyMapping,
  },
  Hostelworld: {
    MappingComponent: MultiOccupancyMapping,
  },
  LocalOTA: {
    MappingComponent: MultiOccupancyMapping,
  },
  RoomCloud: {
    MappingComponent: MultiOccupancyMapping,
  },
  HyperGuest: {
    MappingComponent: MultiOccupancyMapping,
  },
  OpenShopping: {
    MappingComponent: RatesSelectMapping,
  },
  OpenGDS: {
    MappingComponent: MultiOccupancyMapping,
  },
  MemberButton: {
    MappingComponent: RatesSelectMapping,
  },
  HelpingHotels: {
    MappingComponent: RatesSelectMapping,
  },
  Reserva: {
    MappingComponent: RatesSelectMapping,
  },
  ReservaCars: {
    MappingComponent: RatesSelectMapping,
  },
  AbodeConnect: {
    MappingComponent: RatesSelectMapping,
  },
  HeyIceland: {
    MappingComponent: RatesSelectMapping,
  },
  TheSquare: {
    MappingComponent: MultiOccupancyMapping,
  },
  AdvertisingOnline: {
    MappingComponent: MultiOccupancyMapping,
  },
  CultBooking: {
    MappingComponent: MultiOccupancyMapping,
  },
  Cultbooking: {
    MappingComponent: MultiOccupancyMapping,
  },
  Despegar: {
    MappingComponent: MultiOccupancyMapping,
  },
  Hotelbeds: {
    MappingComponent: TreeMapping,
  },
  Webrooms: {
    MappingComponent: MultiOccupancyMapping,
  },
  Inntopia: {
    MappingComponent: RoomRates,
  },
  Travia: {
    MappingComponent: MultiOccupancyMapping,
  },
  Bakuun: {
    MappingComponent: MultiOccupancyMapping,
  },
  stayforRewards: {
    MappingComponent: MultiOccupancyMapping,
  },
  Traveloka: {
    MappingComponent: MultiOccupancyMapping,
  },
  Spot2Nite: {
    MappingComponent: MultiOccupancyMapping,
  },
  VRBO: {
    MappingComponent: TreeMapping,
  },
  ChannexExpress: {
    MappingComponent: RatesSelectMapping,
  },
  Tripla: {
    MappingComponent: MultiOccupancyMapping,
  },
  OTASync: {
    MappingComponent: MultiOccupancyMapping,
  },
  MitchellCorp: {
    MappingComponent: MultiOccupancyMapping,
  },
  TiketCom: {
    MappingComponent: MultiOccupancyMapping,
  },
  MGBedbank: {
    MappingComponent: MultiOccupancyMapping,
  },
  Travelgatex: {
    MappingComponent: MultiOccupancyMapping,
  },
  Feratel: {
    MappingComponent: MultiOccupancyMapping,
  },
  Goibibo: {
    MappingComponent: MultiOccupancyMapping,
  },
  DreamIreland: {
    MappingComponent: MultiOccupancyMapping,
  },
  HRS: {
    MappingComponent: MultiOccupancyMapping,
  },
  OmniHotelier: {
    MappingComponent: MultiOccupancyMapping,
  },
  Guestaps: {
    MappingComponent: MultiOccupancyMapping,
  },
  Hertz: {
    MappingComponent: null,
  },
  Avis: {
    MappingComponent: null,
  },
  Budget: {
    MappingComponent: null,
  },
  Payless: {
    MappingComponent: null,
  },
  Europcar: {
    MappingComponent: null,
  },
  AtlantisORS: {
    MappingComponent: MultiOccupancyMapping,
  },
  Hipcamp: {
    MappingComponent: MultiOccupancyMapping,
  },
  ACEBookingEngine: {
    MappingComponent: RatesSelectMapping,
  },
  Wihp: {
    MappingComponent: RatesSelectMapping,
  },
  PriceTravel: {
    MappingComponent: RoomRates,
  },
  Lido: {
    MappingComponent: MultiOccupancyMapping,
  },
  HotelNetwork: {
    MappingComponent: MultiOccupancyMapping,
  },
  Getaroom: {
    MappingComponent: MultiOccupancyMapping,
  },
  PegiPegi: {
    MappingComponent: MultiOccupancyMapping,
  },
  Ostrovok: {
    MappingComponent: MultiOccupancyMapping,
  },
  HotelPoint: {
    MappingComponent: RatesSelectMapping,
  },
  Szallas: {
    MappingComponent: MultiOccupancyMapping,
  },
  MrAndMrsSmith: {
    MappingComponent: MultiOccupancyMapping,
  },
  Wink: {
    MappingComponent: MultiOccupancyMapping,
  },
  Webready: {
    MappingComponent: MultiOccupancyMapping,
  },
  Hookusbookus: {
    MappingComponent: MultiOccupancyMapping,
  },
  Bookeasy: {
    MappingComponent: RoomRates,
  },
  Hopper: {
    MappingComponent: RatesSelectMapping,
  },
  HopperHomes: {
    MappingComponent: HopperHomesMapping,
  },
  RoomBeast: {
    MappingComponent: MultiOccupancyMapping,
  },
  Reconline: {
    MappingComponent: RoomRates,
  },
  STC: {
    MappingComponent: RoomRates,
  },
  Roibos: {
    MappingComponent: RoomRatesPrimaryRatePerRoom,
  },
  BookOutdoors: {
    MappingComponent: MultiOccupancyMapping,
  },
  HostelHop: {
    MappingComponent: MultiOccupancyMapping,
  },
  Hoterip: {
    MappingComponent: MultiOccupancyMapping,
  },
  DidaTravel: {
    MappingComponent: MultiOccupancyMapping,
  },
  TheTPMGroup: {
    MappingComponent: MultiOccupancyMapping,
  },
  SleepRest: {
    MappingComponent: MultiOccupancyMapping,
  },
  Moverii: {
    MappingComponent: MultiOccupancyMapping,
  },
  TabletHotels: {
    MappingComponent: RoomRates,
  },
  SeminyakVillas: {
    MappingComponent: MultiOccupancyMapping,
  },
  SissaeLiving: {
    MappingComponent: MultiOccupancyMapping,
  },
  B2BGlobal: {
    MappingComponent: MultiOccupancyMapping,
  },
  RVPARKGURU: {
    MappingComponent: MultiOccupancyMapping,
  },
  CampingVision: {
    MappingComponent: MultiOccupancyMapping,
  },
  Bookola: {
    MappingComponent: MultiOccupancyMapping,
  },
  DespegarV2: {
    MappingComponent: RoomRates,
  },
  Nongnooch: {
    MappingComponent: MultiOccupancyMapping,
  },
  BOOKNPAY: {
    MappingComponent: MultiOccupancyMapping,
  },
  Alaric: {
    MappingComponent: MultiOccupancyMapping,
  },
  Waverick: {
    MappingComponent: MultiOccupancyMapping,
  },
  JumpOn: {
    MappingComponent: MultiOccupancyMapping,
  },
  [channelCodes.Ctoutvert.code]: {
    MappingComponent: Ctoutvert,
  },
};

export const CHANNELS_TO_MAPPING = Object.entries(CHANNELS_MAPPING_COMPONENTS).reduce((acc, [channel, conf]) => {
  const mappingType = conf.MappingComponent?.MAPPING_TYPE || null;
  acc[channel] = mappingType;

  return acc;
}, {});

export default function ({ channelCode, mappingStatus, ...rest }) {
  const { t } = useTranslation();

  if (!CHANNELS_MAPPING_COMPONENTS[channelCode]) {
    return null;
  }

  const { MappingComponent } = CHANNELS_MAPPING_COMPONENTS[channelCode];

  if (!MappingComponent) {
    return null;
  }

  // RatesSelect component is a special case, there is no mapping details for it
  // so we need to render it without checking mappingStatus
  if (MappingComponent.MAPPING_TYPE === "RatesSelect") {
    return <MappingComponent {...rest} channelCode={channelCode} />;
  }

  if (mappingStatus === "loading") {
    return <Loading />;
  }

  if (mappingStatus === null) {
    return <FormMessage message={t("channels_page:form:message:fill_settings")} />;
  }

  if (mappingStatus === "error") {
    return (
      <FormMessage icon="api" message={t("common:errors:request_failed")} />
    );
  }

  if (mappingStatus === "loaded" && rest.channelProperties.length === 0) {
    return <FormMessage message={t("channels_page:form:message:choose_property")} />;
  }

  return (
    <MappingComponent {...rest} channelCode={channelCode} />
  );
}
