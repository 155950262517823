import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { ChannexDrawer } from "../../_v2/channex_drawer";
import { BookingManageForm } from "../manage_form";

export const BookingManageDrawerRoutable = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(true);
  const { closePath } = useOutletContext();
  const navigate = useNavigate();
  const { bookingId } = useParams();

  const closeDrawer = () => {
    setIsVisible(false);
  };

  const navigateBack = () => {
    navigate(closePath);
  };

  const title = bookingId ? t("bookings:titles:edit_booking") : t("bookings:titles:create_booking");

  return (
    <ChannexDrawer
      title={title}
      visible={isVisible}
      afterClose={navigateBack}
      onClose={closeDrawer}
    >
      <BookingManageForm
        bookingId={bookingId}
        onFinish={closeDrawer}
      />
    </ChannexDrawer>
  );
};
