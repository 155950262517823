import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import store from "store";

import Loading from "components/loading";

import parseApiErrors from "utils/parse_api_errors";

import WebhookForm from "../form/form";

const { Webhooks } = store;

const toForm = (value, eventTypes) => {
  const formValue = { ...value };

  formValue.headers = formValue.headers
    ? JSON.stringify(formValue.headers || {}, null, 4)
    : null;

  formValue.request_params = formValue.request_params
    ? JSON.stringify(formValue.request_params, null, 4)
    : null;

  formValue.event_mask = formValue.event_mask === "*"
    ? eventTypes
    : formValue.event_mask.split(";");

  return formValue;
};

const toApi = (value, eventTypes) => {
  const apiValue = { ...value };

  apiValue.headers = apiValue.headers ? JSON.parse(apiValue.headers) : null;

  apiValue.request_params = apiValue.request_params
    ? JSON.parse(apiValue.request_params)
    : null;

  apiValue.event_mask = apiValue.event_mask.length === eventTypes.length
    ? "*"
    : apiValue.event_mask.join(";");

  return apiValue;
};

const useEventTypes = () => {
  return useQuery({
    queryKey: "eventTypes",
    queryFn: () => Webhooks.events(),
  });
};

const useEvent = (eventId, eventTypes, options = {}) => {
  return useQuery({
    queryKey: ["events", eventId],
    queryFn: () => Webhooks.find(eventId),
    enabled: !!eventId && !!eventTypes,
    ...options,
  });
};

const FormWrapper = ({ entityId, onClose, onCreate = () => {} }) => {
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState({});
  const [errors, setErrors] = useState({});

  const formRef = useRef();

  const { data: eventTypes, isLoading: isEventTypesLoading } = useEventTypes();
  const { isLoading: isEventLoading } = useEvent(entityId, eventTypes, {
    onSuccess: (data) => {
      const formValue = toForm(data, eventTypes);
      setValue(formValue);
    },
  });

  const handleSubmit = async () => {
    const submitHandler = entityId ? Webhooks.update : Webhooks.create;

    setSubmitting(true);

    try {
      await formRef.current.validate();
      const apiValue = toApi(value, eventTypes);

      setErrors({});

      try {
        const result = await submitHandler({ ...apiValue });
        onCreate(result);
        onClose();
      } catch (error) {
        if (!error.isValidationError) {
          throw error;
        }
        setErrors(parseApiErrors(error));
      }
    } catch (error) {
      // Validation failed
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (isEventTypesLoading || isEventLoading) {
    return <Loading />;
  }

  return (
    <WebhookForm
      value={value}
      eventTypes={eventTypes}
      errors={errors}
      submitting={submitting}
      componentRef={formRef}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

export default FormWrapper;
