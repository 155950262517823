import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Flex } from "antd";
import { uniqBy } from "lodash";

import { useWizard, WizardSidebar } from "components/_v2/wizard";
import { HourSelector } from "components/forms/inputs/hook_form/_v2/hour_selector";
import { FormPropertySelector } from "components/forms/inputs/hook_form/_v2/property_selector";
import { FormRangePicker } from "components/forms/inputs/hook_form/_v2/range_picker";
import { FormSelect } from "components/forms/inputs/hook_form/_v2/select";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { Legend } from "components/forms/layout/legend";
import { AppForm, FormSchemaRenderer } from "components/hook_form";

import dayjs from "utils/dayjs";

import { validationSchema } from "./validation_schema";

export const StepFormInner = ({ otaNames }) => {
  const { t } = useTranslation();
  const today = dayjs().startOf("day");

  const otaNameOptions = uniqBy(otaNames, (i) => i.code.toLowerCase())
    .map((otaName) => ({
      label: otaName.title,
      value: otaName.code,
    }));

  const fields = [{
    render: () => <Legend marginTop={false}>{t("common:headings:basic_info")}</Legend>,
  }, {
    name: "propertyId",
    component: FormPropertySelector,
    disabled: true,
    label: t("common:terms:property"),
  }, {
    name: "otaName",
    component: FormSelect,
    label: t("common:terms:ota"),
    options: otaNameOptions,
  }, {
    name: "otaReservationCode",
    label: t("common:terms:ota_reservation_code"),
  }, {
    name: "bookingDateRange",
    component: FormRangePicker,
    label: t("general:dates"),
    componentProps: {
      disabledDate: (date, { from }) => {
        if (date.isBefore(today)) {
          return true;
        }

        if (from && date.isSame(from, "day")) {
          return true;
        }

        if (from && date.isAfter(from.add(6, "months").startOf("day"))) {
          return true;
        }

        return false;
      },
    },
  }, {
    name: "arrivalHour",
    component: HourSelector,
    label: t("common:terms:arrival_hour"),
  }];

  return (
    <FormSchemaRenderer
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              <SubmitButton layout={false}>
                {t("common:actions:next")}
              </SubmitButton>
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};

export const Step1 = ({ otaNames }) => {
  const { goNext } = useWizard();
  const { setValue, getValues } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner otaNames={otaNames} />
    </AppForm>
  );
};
