import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Flex } from "antd";

import { useWizard, WizardSidebar } from "components/_v2/wizard";
import { SubmitButton } from "components/forms/inputs/hook_form/_v2/submit_button";
import { AppForm, FormSchemaRenderer } from "components/hook_form";

import { CountrySelector } from "../../../../forms/inputs/hook_form/_v2/country_selector";
import { Legend } from "../../../../forms/layout/legend";

import { validationSchema } from "./validation_schema";

export const StepFormInner = () => {
  const { t } = useTranslation();
  const { goPrev } = useWizard();

  const fields = [{
    render: () => <Legend marginTop={false}>{t("common:headings:customer")}</Legend>,
  }, {
    name: "customer.name",
    label: t("common:terms:name"),
  }, {
    name: "customer.surname",
    label: t("common:terms:surname"),
  }, {
    name: "customer.address",
    label: t("common:terms:address"),
  }, {
    name: "customer.zip",
    label: t("common:terms:zip"),
  }, {
    name: "customer.city",
    label: t("common:terms:city"),
  }, {
    name: "customer.country",
    component: CountrySelector,
    label: t("common:terms:country"),
    placeholder: t("common:forms:fields:country:placeholder"),
  }, {
    name: "customer.phone",
    label: t("common:terms:phone"),
  }];

  return (
    <FormSchemaRenderer
      schema={{
        layout: {
          type: "scrollBox",
          sidebar: (
            <WizardSidebar />
          ),
          footer: (
            <Flex style={{ width: "100%" }} gap={8}>
              <Button block size="large" onClick={goPrev}>
                {t("common:actions:prev")}
              </Button>
              <SubmitButton layout={false}>
                {t("common:actions:next")}
              </SubmitButton>
            </Flex>
          ),
        },
        fields,
      }}
    />
  );
};

export const Step2 = () => {
  const { goNext } = useWizard();
  const { setValue, getValues } = useFormContext();

  return (
    <AppForm
      initialValue={getValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        Object.entries(values).forEach(([key, value]) => {
          setValue(key, value);
        });
        goNext();
      }}
    >
      <StepFormInner />
    </AppForm>
  );
};
