import React from "react";
import { useFieldArray, useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Tag, Typography } from "antd";
import get from "lodash/get";

import { Legend } from "components/forms/layout/legend";

import useDrawer from "hooks/use_drawer";

import { daysBetween } from "utils/dates";

import { EditDrawer } from "./edit_drawer";

const RoomItem = ({ room, roomTypes, errors, onRemove, onEdit }) => {
  const { t } = useTranslation();

  const isError = Object.keys(errors || {}).length !== 0;

  const roomType = room && roomTypes.find((type) => type.id === room.roomTypeId);
  const title = roomType?.title ?? t("common:terms:unknown");

  return (
    <Flex justify="space-between">
      <span style={{ alignContent: "center" }}>
        {isError && <Tag icon={<ExclamationCircleOutlined />} color="red">{t("general:validation_error")}</Tag>}
        {title}
      </span>
      <span>
        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
        <Button type="link" danger onClick={onRemove} icon={<DeleteOutlined />} />
      </span>
    </Flex>
  );
};

const buildDaysFromBooking = (booking) => {
  if (!booking.bookingDateRange?.[0] || !booking.bookingDateRange?.[1]) {
    return {};
  }

  return daysBetween(booking.bookingDateRange[0], booking.bookingDateRange[1])
    .slice(0, -1)
    .reduce((acc, day) => {
      acc[day] = null;
      return acc;
    }, {});
};

const buildNew = (booking) => ({
  roomTypeId: null,
  ratePlanId: null,
  days: buildDaysFromBooking(booking),
  occupancy: {
    adults: null,
    children: null,
    ages: [],
  },
  services: [],
  taxes: [],
  guests: [],
});

export const Rooms = ({ name, layout, roomTypes, ratePlans }) => {
  const { t } = useTranslation();

  const [currency, bookingDateRange] = useWatch({ name: ["currency", "bookingDateRange"] });
  const { fields, append, remove, update } = useFieldArray({ name, keyName: "key" });

  // parent form updates rooms values, to properly work with useFieldArray we should use field values from useWatch
  // https://github.com/react-hook-form/react-hook-form/issues/1564
  const entries = useWatch({ name });

  const { isOpen, payload, open, close } = useDrawer();
  const booking = useWatch();
  const formState = useFormState();
  const roomsError = get(formState.errors, name);
  const isBookingDatesSelected = bookingDateRange?.[0] && bookingDateRange?.[1];

  const empty = fields.length === 0 && <Typography.Text type="secondary">{t("common:messages:no_items_added")}</Typography.Text> || null;

  return (
    <>
      <Legend marginTop={layout !== "embedded"} error={roomsError?.message} empty={empty}>
        {t("common:headings:rooms")}
        <Button
          icon={<PlusCircleOutlined />}
          type="link"
          disabled={!isBookingDatesSelected}
          onClick={() => open({ entry: buildNew(booking) })}
        >
          {t("common:actions:add")}
        </Button>
      </Legend>

      {!isBookingDatesSelected && <Typography.Text type="secondary">{t("bookings:messages:select_booking_dates_first")}</Typography.Text>}

      {fields.map(({ key }, index) => (
        entries?.[index] && (
          <RoomItem
            key={key}
            room={entries[index]}
            roomTypes={roomTypes}
            errors={roomsError?.[index]}
            onRemove={() => remove(index)}
            onEdit={() => {
              open({
                entry: entries[index],
                index,
              });
            }}
          />
        )
      ))}

      <EditDrawer
        visible={isOpen}
        room={payload?.entry}
        roomTypes={roomTypes}
        ratePlans={ratePlans}
        currency={currency}
        errors={roomsError?.[payload?.index]}
        onClose={close}
        onSubmit={(data) => {
          if (payload.index === undefined) {
            append(data);
            return;
          }

          update(payload.index, data);
        }}
      />
    </>
  );
};
